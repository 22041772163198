export * from './api-url';

export const config = {
  googleAnalyticsId: 'G-X2SW3450E2',
};

export const pixelConfig = {
  facebookPixelId: '1257274574727086',
  advancedMatching: {},
  options: { autoConfig: true, debug: false },
};
