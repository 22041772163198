export const saveData = (key, data) => {
  window.localStorage.setItem(key, JSON.stringify(data));
};

export const getData = key => {
  return JSON.parse(window.localStorage.getItem(key));
};

export const removeData = key => {
  window.localStorage.removeItem(key);
};
