import React, {
  useState,
  createContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { config, pixelConfig } from 'config';

import { CookieNotice } from './';
import { initialState } from './initial-state';

import { getData, saveData } from './local-storage-utils';
import { useLocation } from 'react-router';

import * as ReactGA from 'components/utility/ReactGA';

const COOKIE_NOTICE_LS = 'cookie-notice';

const usePageView = (callback = () => {}, trigger = []) => {
  const { pathname } = useLocation();

  useEffect(() => {
    callback(pathname);
    // eslint-disable-next-line
  }, [...trigger, pathname, callback]);

  return null;
};

export const CookieContext = createContext();
export const CookieContextProvider = ({ children }) => {
  const [state, setState] = useState(getData(COOKIE_NOTICE_LS) || initialState);
  const [initialized, setInitialized] = useState(false);
  const gaRef = useRef();
  const pixelRef = useRef();

  // handle Google Analytics
  useEffect(() => {
    if (state.noticed) saveData(COOKIE_NOTICE_LS, state);
    // eslint-disable-next-line
  }, [state.noticed]);

  // if (state.noticed && state.form.googleAnalytics.value)
  //   ReactGA.initialize('UA-147447473-2');

  useEffect(() => {
    if (state.noticed && state.form.googleAnalytics && !gaRef.current) {
      // import('react-ga4').then((ReactGA) => {
      //   ReactGA.initialize(config.googleAnalyticsId);
      //   gaRef.current = ReactGA;
      // });
      ReactGA.initialize(config.googleAnalyticsId);
    }
    if (state.noticed && state.form.facebookPixel && !pixelRef.current) {
      import('react-facebook-pixel').then(({ default: ReactPixel }) => {
        ReactPixel.init(
          pixelConfig.facebookPixelId,
          pixelConfig.advancedMatching,
          pixelConfig.options
        );
        pixelRef.current = ReactPixel;
        setInitialized(true);
      });
    }
  }, [
    state.noticed,
    state.form.facebookPixel,
    state.form.googleAnalytics,
    setInitialized,
  ]);

  usePageView(
    (pathname) => {
      if (state.form.googleAnalytics && gaRef.current)
        gaRef.current.pageview(pathname);
      if (state.form.facebookPixel && pixelRef.current)
        pixelRef.current.pageView(pathname);
    },
    [initialized]
  );

  const value = useCallback(state, [state.noticed]);

  return (
    <>
      <CookieContext.Provider value={value}>{children}</CookieContext.Provider>
      <CookieNotice setContext={setState} context={state} />
    </>
  );
};
