const warn = (s) => {
  console.warn('[react-ga]', s);
};

export const initialize = (id) => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  script.setAttribute('async', true);
  script.onload = () => {
    // dont really know if this is needed but i think it is
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gId = id;
    window.gtag('config', window.gId);
  };

  document.body.appendChild(script);
};

export const pageview = (path) => {
  if (!path) return warn('path is required in .pageview()');
  if (!window.gId || !window.gtag) {
    warn(
      'ReactGA.initialize must be called first or GoogleAnalytics should be loaded manually'
    );
    return;
  }

  window.gtag('config', window.gId, { page_path: path });
};
